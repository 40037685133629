<template>
  <div>
    <section class="wrapper--youtube" v-if="youtubeEmbedLink">
      <h3 v-if="useHeader">Video</h3>
      <hr v-if="useHeader" />
      <iframe
        width="100%"
        height="400"
        :src="youtubeEmbedLink"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    youtube_url: {
      type: String,
      require: false,
    },
    useHeader: {
      default: true,
      require: false,
    },
  },
  computed: {
    youtubeEmbedLink() {
      if (this.youtube_url) {
        return this.youtube_url;
      } else if (this.$store.state.v2.propertyPartner.propertyPartner) {
        if (this.$store.state.v2.propertyPartner.propertyPartner.youtube_url) {
          const youtubeId = this.getYoutubeId(
            this.$store.state.v2.propertyPartner.propertyPartner.youtube_url,
          );
          if (youtubeId !== null) {
            return `https://www.youtube.com/embed/${youtubeId}`;
          }
          return null;
        }
        return null;
      } else if (this.$store.state.v2.listing.listing) {
        if (this.$store.state.v2.listing.listing.youtube_url) {
          const youtubeId = this.getYoutubeId(this.$store.state.v2.listing.listing.youtube_url);
          if (youtubeId !== null) {
            return `https://www.youtube.com/embed/${youtubeId}`;
          }
          return null;
        }
        return null;
      }
      return null;
    },
  },
  methods: {
    getYoutubeId(url) {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);

      if (match && match[2].length == 11) {
        return match[2];
      } else {
        return null;
      }
    },
  },
};
</script>
